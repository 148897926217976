<template>
    <div >
      <el-row>
         <el-button
         style="float: left; margin: 7px"
         size="mini"
         type="primary"
         icon="el-icon-plus"
         @click="addEvent('add','','')"
       >
         新增
       </el-button>
       <br>
      <br>
      <br>
      <br>
      </el-row>
      <el-row>
      <el-table
             :data="alertInfo_Table" style="width: 100%;"
             :cell-style="{padding: '0', height: '80px'}"
              @sort-change="tableSortChange"
           >
           <el-table-column  align="center" prop="stationName" label="充電站"  width="200" :show-overflow-tooltip="true" />
           <el-table-column  align="center" label="啟用狀態"  width="180" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
            <el-tag v-if="scope.row.enable=='1'" type="success"> 啟用 </el-tag>
            <el-tag v-else type="danger"> 未啟用 </el-tag>
              </template>

         </el-table-column>
           <el-table-column  align="center" prop="equipAlert" label="設備告警"  width="180" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
            <el-tag v-if="scope.row.equipAlert== '1'" type="success"> {{$t('Yes')}} </el-tag>
            <el-tag v-else type="danger" > {{$t('No')}} </el-tag>
              </template>
           </el-table-column>
           <el-table-column  align="center" prop="transAlert" label="交易告警"  width="180" :show-overflow-tooltip="true" >
           <template slot-scope="scope">
            <el-tag v-if="scope.row.transAlert=='1'"  type="success" > {{$t('Yes')}} </el-tag>
            <el-tag v-else type="danger"> {{$t('No')}} </el-tag>
              </template>
           </el-table-column>

          <!--
           <el-table-column  align="center" label="系統告警"  width="180" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
            <el-tag v-if="scope.row.sysAlert=='1'" type="success" > {{$t('Yes')}} </el-tag>
            <el-tag v-else type="danger"> {{$t('No')}} </el-tag>
              </template>
           </el-table-column>
           --> 

           <el-table-column  align="center" label="超約告警"  width="180" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
            <el-tag v-if="scope.row.overAlert=='1'" type="success" > {{$t('Yes')}} </el-tag>
            <el-tag v-else type="danger"> {{$t('No')}} </el-tag>
              </template>
           </el-table-column>

           <el-table-column  align="center" label="email通知">
            <template slot-scope="scope">
            <el-tag v-if="scope.row.email=='1'" type="success"> {{$t('Yes')}} </el-tag>
            <el-tag v-else type="danger" > {{$t('No')}} </el-tag>
              </template>
           </el-table-column>
           <el-table-column  align="center" label="電話通知">
            <template slot-scope="scope">
            <el-tag v-if="scope.row.phone=='1'"  type="success"> {{$t('Yes')}} </el-tag>
            <el-tag v-else type="danger"> {{$t('No')}} </el-tag>
              </template>
           </el-table-column>
           <el-table-column  align="center" label="Line通知">
            <template slot-scope="scope">
            <el-tag v-if="scope.row.line=='1'"  type="success"> {{$t('Yes')}} </el-tag>
            <el-tag v-else type="danger"> {{$t('No')}} </el-tag>
              </template>
           </el-table-column>           
           <el-table-column  align="center" prop="updateTime" label="啟用日期"  width="200" :show-overflow-tooltip="true" />
           <el-table-column  align="center" label="修改設定">
             <template slot-scope="scope">
                 <el-button  type="success" size="mini" icon="el-icon-edit"  @click="addEvent('modify',scope.row.stationName,scope.row.stationId)"/>
             </template>
           </el-table-column>
           </el-table>
         </el-row>
        <br>
        <br>


    <el-dialog width="1100px"  :visible.sync="dialogEventSettingVisible" @close="dialogOnClose()" >

      <span class="filter-label" style="font: 2em sans-serif;">告警設定</span>
      <!--<el-divider content-position="left"></el-divider>-->

      <br>
      <el-row :gutter="20">

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-divider content-position="left" ><span >告警資訊設定</span></el-divider>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電站 </span>
            <el-select v-model="chargePointOption"  :disabled="chargingStations.length==0"  v-show="this.addevent" filterable class="filter-item" size="small" placeholder="選擇充電站" clearable>
            <el-option v-for="item in chargingStations" :key="item.stationId" :label="item.stationName " :value="item.stationId" />
            </el-select>

            <span class="data-label"  v-show="this.modifyevent"> {{ this.modifyChargeName}}</span>
          </el-col>
           <br>
           <br>
           <br>

           <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">告警是否啟用 </span>
            <el-switch
                    v-model="onOffAlert"
                    active-color="#13ce66"
                    inactive-color="#bfbbbb"
                    style="margin-bottom: 10px">
                </el-switch>
           </el-col>

          <br>
          <br>
          <br>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">告警類別選擇 </span>
            <el-checkbox class="checkbox" v-model="checkedDevice" style="font: 16px sans-serif;" >設備告警</el-checkbox>
            <el-checkbox class="checkbox" v-model="checkedExchange" style="font: 16px sans-serif;" >交易告警</el-checkbox>
           <!--
            <el-checkbox class="checkbox" v-model="checkedSystem" style="font: 16px sans-serif;">系統告警</el-checkbox>
            --> 
            <el-checkbox class="checkbox" v-model="checkedOverLine" style="font: 16px sans-serif;">超約告警</el-checkbox>
          </el-col>

          <br>
          <br>
          <br>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 7px;">
            <span class="data-label">通知方式 </span>
            <el-checkbox class="checkbox" v-model="checkedEmail" >email  </el-checkbox>
            <el-checkbox class="checkbox" v-model="checkedTel"   >電話號碼</el-checkbox>
            <el-checkbox class="checkbox" v-model="checkedLine"  >Line   </el-checkbox>
          </el-col>
         </el-col>



         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <br>
          <br>
          <br>
         <el-divider content-position="left" ><span >通知人員設定</span></el-divider>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 7px;">
            <!--<span class="data-label">人員</span> -->
            <el-checkbox-group v-model="checkedMembers" >
               <el-checkbox v-for="item in memberOptions" :label="item.accountId" :key="item.accountId" :value="item.accountId">{{item.name}}</el-checkbox>
            </el-checkbox-group> 
                       
          </el-col>

          <br>
          <br>
          <br>  
         </el-col>
      </el-row>
      <br>
      <br>
      <br>

   <div style="text-align: center">
   <el-button size="small" @click="clearData()">清空</el-button>
   <el-button size="small" type="primary" @click="setAlertRule()">確定</el-button>
   </div>
   </el-dialog>


    </div>
    </template>

       <script>
       //import Pagination from '@/components/Pagination'
       import { mixins } from '@/views/common/mixins.js'
       import {refreshToken,stopRefreshToken} from "@/utils/auth";
       export default {
           name: 'Station',
           components: {
               //Pagination
           },
           mixins: [mixins],
           data() {
               return {
                serviceId:    this.$store.state.currentAccount.serviceInfo.id,
                modaccountId: this.$store.state.currentAccount.id,   //登入帳號id
                alertInfo_Table:[],
                chargePoint:undefined,
                onOffAlert:false,
                onOffAlertValue:undefined,
                checkedDevice:false,
                checkedExchange:false,
                checkedSystem:false,
                checkedOverLine:false, //2024 0712 bowa add
                chargePointOption:undefined,
                checkedEmail:false,
                checkedTel:false,
                checkedLine:false,
                loginAccount:undefined,
                chargingStations:[],
                checkedMembers:[],
                memberOptions:[],

                dialogEventSettingVisible:false,

                chargingloading: true,

                modifyChargeId:undefined,
                modifyChargeName:undefined,
                addevent:false,
                modifyevent:false

               };
           },
         watch: {


         },
         created() {

            this.loginAccount=this.$store.state.currentAccount.name
            this.getEventList();
         },

         beforeDestroy() {
            //stopRefreshToken();
         },

         mounted() {

         },
         methods: {

            dialogOnClose(){
               this.dialogEventSettingVisible=false
            },

            getnotifyStationList(){
               this.targetUrl = "/api/v1/notifyset/getnotifyStationList?serviceId="+this.serviceId
               this.axios.get(this.targetUrl).then(res => {
               this.chargingStations=res.data
               })
            },

            getStationinfoList(val3) {
               this.checkedMembers=[]
               this.targetUrl = "/api/v1/notifyset/getStationinfoList?serviceId=" + this.serviceId 
               if(val3!='')  this.targetUrl = this.targetUrl + "&stationId=" + val3
               this.axios.get(this.targetUrl).then(res => {
               this.memberOptions=res.data
               this.memberOptions.map( 
                   (item) => {
                    if(item.enableaccount != 0) this.checkedMembers.push(item.accountId)
                    return item
                  }                 
                 )
               })                              
            },

            addEvent(val1,val2,val3){
               this.onOffAlert=false
               this.checkedDevice=false
               this.checkedExchange=false
               this.checkedSystem=false
               this.checkedEmail=false
               this.checkedOverLine=false
               this.checkedTel=false
               this.checkedLine=false
               this.checkedMembers=[]
               this.modifyChargeId=undefined
               this.modifyChargeName=undefined

               if(val1=='add')
               {
                  //新增
                  this.addevent=true
                  this.modifyevent=false
                 // this.modifyChargeId=this.chargePointOption.id  //從下拉是選單選擇對應到id
                  this.getStationinfoList(val3)
               }
               else if(val1=='modify')
               {
                  //修改
                  this.addevent=false
                  this.modifyevent=true
                  this.modifyChargeName=val2
                  this.getAlertRule(val3)
                  this.modifyChargeId=val3
                  this.getStationinfoList(val3)
               }
               this.getnotifyStationList();
               this.dialogEventSettingVisible=true
            },

            getEventList(){
               this.targetUrl = "/api/v1/notifyset/getnotifySetAllData?serviceId="+this.serviceId
               this.axios.get(this.targetUrl).then(res => {
                 this.alertInfo_Table=res.data.content
               })               
            },

            clearData(){
            this.onOffAlert=false
            this.checkedDevice=false
            this.checkedExchange=false
            this.checkedSystem=false
            this.checkedOverLine=false
            this.checkedEmail=false
            this.checkedTel=false
            this.checkedLine=false
            this.checkedMembers=[] 
            },

            getAlertRule(val){
               this.targetUrl =  "/api/v1/notifyset/getnotifySetList?serviceId="+this.serviceId+"&stationId="+val
               this.axios.get(this.targetUrl).then(res => {

               if(res.data[0].enable=='1')     this.onOffAlert=true
               else                            this.onOffAlert=false

               if(res.data[0].equipAlert=='1') this.checkedDevice=true
               else                            this.checkedDevice=false

               if(res.data[0].transAlert=='1') this.checkedExchange=true
               else                            this.checkedExchange=false

               if(res.data[0].overAlert=='1') this.checkedOverLine=true
               else                           this.checkedOverLine=false

               if(res.data[0].sysAlert=='1')   this.checkedSystem=true
               else                            this.checkedSystem=false

               if(res.data[0].email=='1')      this.checkedEmail=true
               else                            this.checkedEmail=false

               if(res.data[0].phone=='1')      this.checkedTel=true
               else                            this.checkedTel=false

               if(res.data[0].line=='1')       this.checkedLine=true
               else                            this.checkedLine=false

               })

            },

            setAlertRule(){
            if((this.addevent==true) && (this.chargingStations.length==0))
               return this.$message({ message: '沒有可設定的充電站可選擇', type: 'warning' })

            if(this.modifyChargeId==undefined) this.modifyChargeId=this.chargePointOption

            this.targetUrl = "/api/v1/notifyset/setnotifySet?serviceId="+this.serviceId+"&stationId="+this.modifyChargeId+"&enable="

               if(this.onOffAlert==true)      this.targetUrl += "1&equipAlert="
               else                           this.targetUrl += "0&equipAlert="

               if(this.checkedDevice==true)   this.targetUrl += "1&transAlert="
               else                           this.targetUrl += "0&transAlert="

               if(this.checkedExchange==true) this.targetUrl += "1&sysAlert="
               else                           this.targetUrl += "0&sysAlert="

               if(this.checkedSystem==true)   this.targetUrl += "1&overAlert="
               else                           this.targetUrl += "0&overAlert="

               if(this.checkedOverLine==true)   this.targetUrl += "1&email="
               else                           this.targetUrl += "0&email="

               
               if(this.checkedEmail==true)    this.targetUrl += "1&phone="
               else                           this.targetUrl += "0&phone="

               if(this.checkedTel==true)      this.targetUrl += "1&line="
               else                           this.targetUrl += "0&line="

               if(this.checkedLine==true)     this.targetUrl += "1&loginuser="
               else                           this.targetUrl += "0&loginuser="

               this.targetUrl = this.targetUrl+ this.loginAccount

               this.axios.get(this.targetUrl).then( 
                  res => {

                           if(res.data.status=='ok')
                           {
                             this.getEventList();
                             this.dialogEventSettingVisible=false
                             return this.$message({ message: '告警設定更新成功', type: 'warning' })
                            
                           }
                           else
                             return this.$message({ message: '告警設定更新失敗', type: 'warning' })
                         }
               )

               this.targetUrl = "/api/v1/notifyset/addstationSet?serviceId="+this.serviceId+"&stationId="+this.modifyChargeId+"&modaccountId="+this.modaccountId
               this.targetUrl = this.targetUrl + "&straccountId=" + this.checkedMembers
               this.axios.get(this.targetUrl).then( 
                  res => {

                           if(res.data.status=='ok')
                           {
                             this.getEventList();
                             //return this.$message({ message: '告警通知人員更新成功', type: 'warning' })
                           }
                           else
                             return this.$message({ message: '告警通知人員更新失敗', type: 'warning' })
                         }
               )
            }
         }
       }

       </script>

<style lang="scss" scoped>
.checkbox {
zoom:100%;
}
.alert {
zoom:100%;
}


.data-label {
  width: 120px; display: inline-block;
}
.data-amount{
  font-size: 1.2em;
  font-weight: bolder;
  color: #409eff;
}
</style>
